@import '../../resources/media-queries.scss';

.projects{
    &__container{
        padding:20px;
        display: flex;
        flex-direction: column;
        background-color: #edf7ee;
    }
    &__image{
        height:335px;
        width:100%;
        object-fit: cover;
    }
    &__title{
        font-size: 18px;
        line-height: 1.56;
        color: #4CAF50;
        padding-top: 10px;
    }
    &__date{
        font-size: 12px;
        font-weight: bolder;
        color: #535f6e;
        padding:5px 0;
    }
    &__description{
        font-size: 18px;
        line-height: 1.67;
        color: #535f6e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__individual{
        padding-bottom: 20px;
    }
    &__see-more{
          font-size: 14px;
          color:#000033;
          padding-top: 5px;
          cursor: pointer;
    }
    @include tablet{
        &__container{
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__individual{
            width:46%;
            padding:0 10px 20px;
        }
    }
    @include desktop{
        &__container{
            flex-direction: row;
            flex-wrap: wrap;
            padding:40px 10%;
        }
        &__individual{
            width:30%;
            padding:0 20px 20px;
        }
    }
}