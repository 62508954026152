@import '../../resources/media-queries.scss';

.header {
    background: radial-gradient(circle, rgba(76, 175, 80, 0.2) 0%, rgba(76, 175, 80, 0.5) 40%, rgba(76, 175, 80, 0.8) 80%, rgba(76, 175, 80, 1) 100%);


    padding:20px;
  &__logo{
    width: 75px;
    height: auto;
    object-fit: contain;
  }
  &__logo-box{
      display: flex;
      flex-direction: row;
  }
  &__name-box{
      display: flex;
      flex-direction: column;
      padding:5px 10px;
  }
  &__general-name{
      color:#edf7ee;
      font-size: 13px;
      font-weight: 500;
  }
  &__specific-name{
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      padding-top: 5px;
  }
  &__supramenu{
     display: none;
  }
  &__mobile-menu{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
    @include tablet {
        padding:20px 40px;
    }

    @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:30px 10%;

        &__supramenu{
            display: block;
            list-style-type: none;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
        }
        &__supramenu-item{
            padding:10px 15px;
            font-size: 14px;
            font-weight: 500;
            color: #edf7ee;  
            cursor: pointer;  
        }
        &__supramenu-item:hover{
            border:1px solid #edf7ee;
        }
    }
}