@import '../../../resources/media-queries.scss';

.sg{

    &__name-normal{
        display: none;
    }
    &__specific-name{
        color:#001f70;
    }
    &__mobile-info{
        display: flex;
        flex-direction: row;
        margin-top: 6px;
    }
    &__name-round{
        background-color: #001f70;
        height:35px;
        width:35px;
        border-radius: 50%;
    }
    &__name-mobile-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__name-mobile{
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        padding-top: 7px;
    }
    &__icon{
        width:14px;
        padding:0 0 7px 5px;
    }
    &__header-container{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        padding:20px;
    }
    &__title-section{
        padding:50px 0;
        background-color: #f2f2f2;
        position: relative;
    }
    &__title-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:50%;
        margin:0 auto;
    }
    @include tablet{

    }
    @include desktop{
        &__header-container{
            padding:30px 10%;
        }
        &__name-normal{
            display: block;
        }
        &__mobile-info{
            display: none;
        }
        &__desktop-info{
            display: flex;
            flex-direction: column;
        }
        &__class{
            font-size: 14px;
            color: #535f6e;
            padding-top: 5px;
        }
        &__name-normal{
            display: flex;
            flex-direction: row;
        }
        &__icon{
            margin-left: 10px;
        }
        &__schedule{
            width:100%;
        }
    }
}