@import '../../resources/media-queries.scss';


.sc{
    padding: 30px 20px;

    &__edit-icon{
        width:16px;
        cursor: pointer;
    }
    &__map-div{
        width:100%;
    }
    &__title{
        padding:20px 0 30px;
        border-bottom: 1px solid #f2f2f2;
    }
    &__table{
        width:100%;
        margin-bottom: 20px;
        padding:20px;
        background-color: #ffff;
    }
    &__student-name-box{
        background-color: #ffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:20px 0 20px 20px;
        border-bottom: 1px solid #f2f2f2;
    }
    &__down-arrow{
        width:18px;
        margin-right: 30px;
    }
    &__row{
        padding-bottom: 10px;
    }
    &__table-title{
        font-family: Cabin;
        font-size: 16px;
        font-weight: 500;
        color: #535f6e;
        padding-bottom: 15px;
    }
    &__display-closed{
        display: none;
    }
    &__map-div{
        margin:10px 0;
    }
    &__note-add{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    @include desktop{
        padding:30px 10%;
    }
}