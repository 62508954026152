@import '../../resources/media-queries.scss';

@import '../../resources/media-queries.scss';

.amenu{
    display: none;
    @include desktop{
        min-height: 100vh;
        display: block;
        background: #001529;
        width:15%;
        min-width: 300px;

        &__link{
            font-size: 18px;
        }
       &__container{
        min-height: 100vh;
        padding: 0;
       }
       &__logo-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width:100px;
            padding:20px 0 10px;

        }
       }
       &__name-box{
        color:#fff;
        text-align: center;
        
       }
       &__general-name{
        text-align: center;
        margin-bottom: 0!important;
       }
       &__specific-name{
        text-align: center;
        font-weight: lighter;
        margin-top: 5px!important;
       }
    }
}