@import '../../resources/media-queries.scss';

.prof-grades{
    padding:20px;
    &__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__individual-class{
        background-color: #ffff;
        width:80%;
        padding:15px 10px;
        margin:10px auto;
        border-radius: 5px;
        cursor: pointer;
    }
    &__class-name{
        text-align: center;
    }
    @include desktop{
        padding:40px 10%;

        &__wrapper{
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__individual-class{
            width:30%;
            padding:15px 10px;
            margin:10px 0;
            border-radius: 5px;
        }
    }
}