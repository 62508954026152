@import '../../resources/media-queries.scss';

.organigram{
    &__container{
        padding:20px;
        background-color: #edf7ee;
    }
    &__individual{
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #a6c0dd;
        margin:20px 0;
        padding-bottom: 5px;
    }
    &__person, &__function{
        font-size: 18px;
        line-height: 1.56;
        color: #20252c;
    }
    &__function{
        color: #535f6e;
    }

    @include tablet{
        &__individual{
            flex-direction: row;
            justify-content: space-between;
        }
    }

    @include desktop{
        &__container{
            padding:20px 25%;
        }
        &__individual{
            flex-direction: row;
            justify-content: space-between;
            padding:10px 0;
        }
    }
}