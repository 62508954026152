@import '../../resources/media-queries.scss';

.homepage {
  &__latest-news-part {

    padding: 40px 20px;
    background: linear-gradient(to bottom right, #4CAF50, #ffffff);
  }
&__vision-part{
  position: relative;
}
  &__latest-news-div {
    display: flex;
    flex-direction: column;
  }
  &__slider{
    padding-top: 20px;
  }

  &__latest-news-subtitle {
    color: #fff;
    padding-bottom: 40px;
    font-weight: lighter;
  }

  &__banner {
    background-image: url('/images/presentation/banner.png');
    height: 280px;

    object-fit: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 40px 20px;
  }

  &__motto {
    font-style: italic;
    color: #ffff;
    font-size: 24px;
    padding-top: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  }

  &__motto-author {
    color: #ffff;
    padding-top: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  }

  &__school-img {
    position: absolute;
    width: 200px;
    height: 200px;
    bottom: 0;
  }

  &__latest-news-img {
    width: 100%;
    height: 300px;
    object-fit: cover;

    border-radius: 15px;
  }

  &__motto-section {
    background-image: url('/images/presentation/texture.jpg');
    padding: 30px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 1.4;

    h5 {
      padding-top: 20px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__latest-news {
    padding: 20px 0 10px;
  }

  &__latest-news-title {
    font-size: 22px;
    font-weight: lighter;
    line-height: 1.58;
    color: #ffffff;
    padding-bottom: 10px;
  }

  &__standard-button {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 5px;
    background-color: #2f6a31;
    margin: 10px 0;
    cursor: pointer;
  }

  &__standard-button:hover {
    background-color: #4d0019;
    border: none;
  }

  &__standard-button:focus {
    outline: none;
  }

  &__see-more:hover {
    border: 1px solid #2f6a31;
    background-color: initial;
  }

  &__standard-button-writing {
    font-size: 12px;
    letter-spacing: 1px;
    color: #ffffff;
  }

  &__see-more {
    background-color: transparent;
    border: 1px solid #edf7ee;
  }

  &__middle-part {
    padding: 50px 20px;
    border-radius: 15% 20% 0 0;
    position: relative;
    background-color: #ffff;
    top: -60px
  }

  &__middle-part-info {
    font-size: 18px;
    line-height: 1.67;
    color: #535f6e;
    text-indent: 20px;
    padding-bottom: 10px;
  }

  &__projects {
    padding: 20px;
    background-image: url('/images/presentation/texture.jpg');

  }

  &__projects-see-more {
    display: none;
  }

  &__project-image {
    width: 100%;
    height: 335px;
    object-fit: cover;
  }

  &__project-title {
    font-size: 18px;
    line-height: 1.56;
    color: #4CAF50;
    padding: 10px 0 5px;
  }

  &__project-info {
    font-size: 18px;
    line-height: 1.67;
    color: #535f6e;
  }

  &__projects-see-more-mobile {
    font-size: 14px;
    color: #2f6a31;
    text-align: center;
    padding-top: 20px;
  }

  &__news-date {
    font-size: 12px;
    color: #535f6e;
    padding-top: 5px;
  }

  &__link {
    text-decoration: none;
    color: initial;
    cursor: pointer;
  }

  &__news-wrapper {
    background-color: #ffffff;
  }

  &__projects-container {
    margin: 20px 0;
  }

  &__about-title {
    padding-bottom: 20px;
    text-align: center;
  }

  &__pen-icon {
    width: 300px;
    height: 100px;
    position: absolute;
    bottom: -10px;
    left: 70px;
  }

  &__latest-news-subtitle {
    padding-bottom: 5px;
  }

  &__line {
    height: 2px;
    width: 200px;
    background-color: #2f6a31;
    border-radius: 3px;
    margin-bottom: 40px;
  }
  &__vision-part{
    background-image: url('/images/presentation/texture.jpg');
    padding:40px 20px;
  }
  &__see-details{
    text-align: left;
    color:#535f6e;
    padding-bottom: 5px;

  }


  @include tablet {}

  @include desktop {
    &__banner {
      height: 400px;
      align-items: initial;
      padding: 100px 20% 30px;

    }
    &__vision-part{
      padding:60px 10%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      background-color: #008CBA;
    }

    &__line {
      width: 250px;
    }

    &__pen-icon {
      width: 600px;
      height: auto;
      position: absolute;
      bottom: -50px;
      left: 60%;
    }

    &__discover-section {
      padding: 50px 10%;
    }

    &__latest-news-div {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

    }

    &__latest-news-subtitle {
      font-size: 30px;
    }

    &__latest-news-part {
      padding: 60px 10%;
    }

    &__motto {
      font-size: 34px;
      width: 70%;

    }

    &__motto-author {
      font-size: 20px;
    }

    &__about-title {
      font-size: 24px;
      text-align: left;
      padding: 30px 0;
    }

    &__latest-news-img {
      width: 50%;
      height: 450px;
    }

    &__latest-news {
      width: 30%;
    }

    &__latest-news-title {
      font-size: 28px;
    }

    &__latest-news-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
    }

    &__standard-button {
      width: 48%;
    }
    &__middle-part{
      display: flex;
      flex-direction: row;
    }
    &__slider{
      width:70%;
    }
    &__middle-part,
    &__projects {
      padding: 40px 10%;
    }

    &__middle-part-info {
      width: 70%;
    }

    &__projects-see-more-mobile {
      display: none;
    }

    &__projects-see-more {
      display: block;
      font-size: 16px;
      color: #2f6a31;
      cursor: pointer;
    }

    &__projects-title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    &__projects-container {
      width: 45%;
    }

    &__news-date {
      padding-top: 10px;
    }

    &__projects-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 105%;
    }
  }
}

#homepage-description {
  width: 100%;
  text-align: initial;
}