@import '../../resources/media-queries.scss';

.grades{
    &__container{
        padding:20px;
    }
    &__form{
        display: flex;
        flex-direction: column;
        margin:10px auto;
    }
    &__search-box{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }
    &__search-icon{
        width:18px;
        position: relative;
        right: 20px;
    }
    &__input{
        border: none;
        border-bottom: 1px solid #888;
        background: transparent;
        margin:10px 0;
        padding:8px 0;
        width:100%;
        font-size: 16px;
        line-height: 1.56;
        font-weight: bold;
        color: #535f6e;
        text-indent: 5px;
    }
    
    &__input::placeholder, textarea::placeholder{
        font-size: 16px;
        line-height: 1.56;
        color: #535f6e;
    }
    &__wrapper{
        margin-top:40px;
    }
    &__adding-grades{
        font-size: 18px;
        line-height: 1.56;
        text-align: center;
        color: #006cd4;
    }
    &__general-media{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:20px;
    }
    &__grades-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &__info{
        width:50%;
    }
    &__media-box{
        width:50%;
        margin-top: 10px;
    }
    &__subject{
        text-align: left;
    }
    &__subject-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:0 20px 10px;
    }
    &__arrow-icon{
        width:16px;
        cursor: pointer;
    }
    &__closed-box{
        display: none;
    }
@include tablet{
    &__container{
        padding:20px 50px;
    }
    &__general-media{
        padding:20px 40px;
    }

    &__subject-box{
        padding:0 18% 20px;
    }
}

@include desktop{
    &__container{
        margin:40px 10%;
    }
    &__form{
        flex-direction: row;
        justify-content: space-between;
        width:100%;
    }
    &__search-box{
        width:25%;
    }
    &__info{
        width:15%;
    }
    &__media-box{
        width:20%;
        margin-top: 0;
    }
    &__date{
        padding-bottom: 10px;
    }
    &__adding-grades{
        padding-top: 10px;
    }
    &__general-media{
        padding:20px 7%;
    }
    
    &__subject-box{
        padding:0 7% 20px;
    }
}
}