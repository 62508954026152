@import '../../resources/media-queries.scss';

.footer{
    background-color: #367c39;
    padding:30px 20px;
    &__logo{
        width: 30px;
        height: 40px;
      }
    &__logo-box{
        display: flex;
        flex-direction: row;
    }
    &__name-box{
        display: flex;
        flex-direction: column;
        padding:5px 10px;
    }
    &__general-name{
        color:#edf7ee;
        font-size: 13px;
        font-weight: 500;
        color: #a6c0dd;
    }
    &__specific-name{
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        padding-top: 5px;
    }
    &__contact{
        display: flex;
        flex-direction: column;
        padding: 20px 0 10px;
    }
    &__info{
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #ffffff;
    }
    &__separator{
        height:1px;
        background-color: #edf7ee;
        width:100%;
        margin:20px auto;
        justify-content: center;
        margin:0 auto;
        width:100%;
    }
    &__links{
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
    
    }
    &__link{
        text-decoration: none;
        color:initial;
        cursor: pointer;

    }
    &__item-box{
        width:50%;
        margin:5px 0;
    }
    &__item{
        font-family: Cabin;
        font-size: 16px;
        font-weight: 500;
        color: #edf7ee;
        margin:5px 0;
    }
    &__social-box{
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 55px;
        right: 0px;
    }
    &__social-icon{
        width:24px;
        padding:5px;
    }
    &__contact-social{
        position: relative;
    }

    @include tablet{
        padding:30px 40px;
    &__links{
        width:50%;
    }
    &__social-box{
        right: 50%;
    }
}
    @include desktop{
        padding:40px 10% 50px;
    &__logo{
        width: 50px;
        height: 70px;
      }
    &__general-name{
        color:#edf7ee;
        font-size: 16px;
    }
    &__specific-name{
        font-size: 22px;
        font-weight: lighter;
    }
    &__wrapper{
        display: flex;
        flex-direction: row;
        width:100%;
    }
    &__first-section{
        width:80%;
    }
    &__links{
        width:100%;
        height:70px;
    }
    &__item-box{
        width:25%;
        margin:0;
    }
    &__item{
        margin:0;
    }
    &__separator{
        display: none;
    }
    &__contact-desktop{
        flex-direction: row;
    }
    &__contact-social{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__social-box{
        display: flex;
        position: initial;
        padding:10px 5% 0 0;
    }
    &__desktop-info{
        padding-right: 5px;
    }
    &__social-icon{
        padding-right: 20px;
        width:28px;
    }
    &__desktop-separator{
        height:1px;
        background-color: #edf7ee;
        width:100%;
        margin:30px auto 40px;
        justify-content: center;
        margin:0 auto;
        width:100%;
    }
}
}