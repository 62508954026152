.admin{
    display: flex;
    flex-direction: row;
    &__wrapper{
        padding:30px 5%;
        width:60%;
    }
    &__title{
        font-size: 28px;
        font-weight: 500;
        color:#222222;
    }
    &__subtitle{
        font-weight: 500;
        color:#222222;
    }
    &__form{
        display: flex;
        flex-direction: column;
        width:60%;
        margin-top: 40px;
    }
    &__label{
        margin:20px 0 5px 0;
        font-weight: 500;
        color:#222222;
    }
    &__field{
        border:1px solid #e6e6ff;
        height:45px;
        border-radius: 5px;
        font-size: 16px;
    }
    &__field:focus{
        outline: none;
    }
    &__field::placeholder{
        font-size: 14px;
        text-overflow: bolder;
        text-indent: 5px;
    }
    &__submit-button{
        background-color:#00135f;
        height:40px;
        border:none;
        border-radius: 5px;
        margin-top: 30px;
        color:#ffff;
        font-size: 16px;
        cursor: pointer;
    }&__submit-button:hover{
        background-color: #00051a;
    }
    &__attachement{
        margin-top: 20px;
    }
    &__attachement:focus{
       outline: none;
    }
    &__textarea{
        height:auto;
    }
    &__existing-documents{
        margin-top: 60px;
        border-top: 1px solid #e6e6ff;
        padding-top:20px;
    }
    &__existing-docs-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__card-title{
        margin:0;
        font-weight: 500;
        font-size: 18px;
    }
    &__card{
        border:1px solid #e6e6ff;
        padding:20px;
        width:44%;
        margin:10px 0;
    }
    &__document-box{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        img{
            width:22px;
            height:22px;
        }
        h3{
            font-size: 16px;
            font-weight: light;
            margin:0 0 0 10px;
        }
    }
    &__actions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        h4{
            cursor: pointer;
            margin-bottom: 0;
        }
    }
    &__delete{
        color:red;
    }
    &__date-box{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        img{
            width:18px;
            height:18px;
            margin:5px 10px 0 0;
        }
    }
    &__card-date{
        margin:5px 0 10px;
        font-size: 14px;
        color:#666666;
        font-weight: lighter;
    }
    &__no-documents{
        font-weight: lighter;
    }
    &__card-description{
        margin:0 0 15px 0;
    }
    &__first-doc{
        padding-bottom: 10px;
    }
}