@import '../../resources/media-queries.scss';

.documents{
    &__wrapper{
        padding:20px;
        background-color: #edf7ee;
    }
    &__search-form{
       width:100%;
       padding:20px 0;
       box-sizing: border-box;
    }
    &__search-input{
        display: flex;
        flex-direction: row;
        position: relative;
        input{
            width:100%;
        }
        img{
            width:18px;
            height:18px;
            position: absolute;
            top:15px;
            right:0;
        }
    }
    &__number{
        font-size: 24px;
        font-weight: bold;
        line-height: 1.58;
        color: #20252c;
    }
    &__field{
       width:100%;
       margin:10px 0;
       border:none;
       border-bottom: 1px solid #979ea6;
       font-size: 18px;
       line-height: 1.56;
       background:transparent;
       color: #20252c;
       font-family: 'Cabin', sans-serif;
       margin-top: 10px;
    }
    &__field::placeholder{
        font-size: 18px;
        line-height: 1.56;
        font-family: 'Cabin', sans-serif;
        color: #20252c;
    }
    &__field:focus{
        outline:none;
    }

    &__card{
        border:1px solid #b8bcc0;
        padding:20px;
        margin:20px 0;
        border-radius: 5px;
    }
    &__card-title{
        font-size: 18px;
        line-height: 1.56;
        color: #4CAF50;
      
    }
    &__card-intro{
        display: flex;
        flex-direction: row;
        h5{
            font-size: 13px;
            font-weight: bold;
            color: #535f6e;
            padding:0 3px;
          
        }
    }
    &__card-info{
        font-size: 16px;
        line-height: 1.63;
        color: #535f6e;
        margin-top: 5px;
      
    }
    &__see-doc{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #4CAF50;
        cursor: pointer;
      
    }
    @include tablet{
        &__wrapper{
            padding:20px 40px;
        }
    }

    @include desktop{
        &__wrapper{
            padding:20px 10%;
        }
        &__search-form{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__search-input{
            width:45%;
        }
        &__date{
        width:20%;
        }
        &__select-box{
            width:30%;
            position: relative;
            top:5px;
        }
        &__container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &__card{
            width:21%;
            margin:20px 0;
        }
    }
}