@import '../../resources/media-queries.scss';

.submenu {
  &__wrapper {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    box-sizing: border-box;
    width: 18%;
    background: #fff;
    border: 1px solid white;
    border-top: 3px solid white;
    border-radius: 20px;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 135px;
    z-index: 11;
    padding: 2% 0;
    height: auto;


    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
    &__column {
      padding: 0 5%;
      width: 70%;
    }
  
    &__title {
      font-weight: bold;
      line-height: 40px;
  
      a {
        text-decoration: none;
        color: black;
        cursor: pointer;
      }
    }
    &__links {
      list-style-type: none;
      line-height: 50px;
      padding: 0;
      width:100%;
      margin: 0;
    }
    &__link {
      line-height: 30px;
      width:100%;
      padding: 0;
      margin: 0;
      color: #20252c;
  
      a {
        text-decoration: none;
        color: black;
        
        cursor: pointer;
      }
      span{
        width:100%;
      }
    }
    &__link:hover{
      border-bottom: 1px solid #20252c;
    }
  }
  
//   .columnSeparator {
//     position: absolute;
//     background-color: black;
//     top: 10%;
//     right: 50%;
//     height: 80%;
//     width: 1px;
//   }
  