@import '../../resources/media-queries.scss';

.values{
    &__container{
        padding:20px;
        background-color: #edf7ee;
    }
    &__list{
        list-style-type: none;
    }
    &__individual-value{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #a6c0dd;
        padding-bottom: 5px;
    }
    &__value-number{
        font-size: 30px;
        font-weight: bold;
        line-height: 1.5;
        color: #4CAF50;
        position: relative;
        right: 20px;
    }
    &__item{
        font-size: 18px;
        line-height: 1.56;
        color: #20252c;
    }

    @include tablet{
        &__value-number{
            bottom:10px;
        }
    }

    @include desktop{
        &__container{
            padding:40px 25%;
        }
        &__value-number{
            bottom:10px;
        }
    }
}