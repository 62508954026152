@import '../../resources/media-queries.scss';

.admission{
    &__container{
        padding:20px;
        background-color: #edf7ee;
    }
    &__subtitle{
        font-size: 18px;
        line-height: 1.56;
        color: #20252c;
    }
    &__individual{
        display: flex;
        flex-direction: column;
        padding:20px;
        border: 1px solid #4CAF50;
        margin:20px 0;
    }
    &__number{
        font-size: 20px;
        line-height: 1.5;
        cursor: pointer;
        padding-bottom: 5px;
        color: #4CAF50;
    }
    &__info{
        font-size: 16px;
        line-height: 1.56;
        font-weight: lighter;
        color: #535f6e;
    }
    &__opportunities{
        background-color: #f2f2f2;
        padding:30px 20px;
    }
    &__opportunities-list{     
        list-style-type: none;
        padding-left: 0;
        margin-top: 5px;
        li{
            font-weight: bolder;
        }
    }
    &__description{
        margin:0;
        font-size: 18px;
        line-height: 1.56;
        word-wrap:break-word;
    }
    &__link-box{
        display: flex;
        flex-direction: column;
        h4{
            font-weight: lighter;
        }
    }
    @include tablet{
        &__link-box{
            flex-direction: row;
            h4{
                padding-top: 3px;
            }
        }
    }
    @include desktop{
        &__container{
            padding:40px 25%;
        }
        &__individual-type{
            display: flex;
            flex-direction: row;
        }
        &__individual{
            width:45%;
            margin-right: 30px;
        }
        &__opportunities{
            padding:20px 25%;
        }
        &__opportunities-list{
            margin-top: 20px;
        }
        &__link-box{
            flex-direction: row;
            h4{
                padding-top: 3px;
            }
        }
    }
}