@import '../../../resources/media-queries.scss';

.login{
    background-color: #00135f;
    min-height:100vh;
    &__logo{
        width:65px;
        height: auto;
        object-fit: contain;
      }
      &__logo-box{
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding:40px 0 30px;
      }
      &__name-box{
          display: flex;
          flex-direction: column;
          padding:5px 10px;
      }
      &__general-name{
          color:#a6c0dd;
          font-size: 13px;
          font-weight: 500;
          color: #a6c0dd;
      }
      &__specific-name{
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          padding-top: 5px;
      }
      &__container{
          background-color: #ffff;
          border-radius: 10px;
          margin:20px;
          padding:40px 20px;
          height:350px;
      }
      &__user-types-box{
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding:10px 0;
      }
      &__user-button{
        width: 145px;
        height: 45px;
        border-radius: 5px;
        border:solid 2px #979ea6;
        background-color: transparent;
      }
      &__user-student{
          border-right: none;
      }
      &__user-button:hover{
        border: solid 2px #006cd4;
      }
      &__user-button:focus {
        outline: 0;
      }
      &__user{
        font-size: 16px;
        font-weight: bolder;
        line-height: 1.75;
        text-align: center;
        color:#979ea6;
      }
      &__user:hover{
        color: #006cd4;
      }
      &__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:20px 0;
    }
 
    &__input, textarea{
        border: none;
        border-bottom: 1px solid #888;
        background: transparent;
        margin:10px 0;
        padding:8px 0;
        width:100%;
        font-size: 16px;
        line-height: 1.56;
        font-weight: bold;
        color: #535f6e;
        text-indent: 5px;
        font-family: 'Domine', serif;
    }
    
    &__input::placeholder, textarea::placeholder{
        font-size: 16px;
        line-height: 1.56;
        color: #535f6e;
        font-family: 'Domine', serif;
    }
    &__submit-button{
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border:none;
        margin:10px 0;
        background-color: #006cd4;
        cursor:pointer;
    }
    &__submit-button:hover{
        background-color: #005cb3;
    }
    &__button-writing{
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
    }
    &__password-question{
        font-size: 12px;
        letter-spacing: 1px;
        color: #006cd4;
        text-align: center;
    }
    &__password-question:hover{
        color:#003566;
        cursor: pointer;
    }

    @include desktop{
        height:1000px;
        &__container{
            margin:40px 30%;
            padding:40px;
            height:450px;
        }
        &__submit-button{
            margin:30px 0;
            width:40%;
        }
        &__title{
            text-align: center;
            margin-bottom: 20px;
        }
        &__form{
            margin:30px 50px;
        }
        &__submit-box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__password-question{
            padding:40px 0;
        }
    }
}