@import '../../resources/media-queries.scss';

.menu{
    &__links{
        display: none;
    }

    @include tablet{

    }
    @include desktop{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &__links{
            list-style-type: none;
            display: flex;
            flex-direction: row;

            
        }
        &__item{
            font-size: 16px;
            color: #ffffff;
            padding:0 10px;
            font-family: Domine;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);


        }
        &__item:hover{
            border:1px solid #edf7ee;
        }

    
    }
}