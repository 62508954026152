@import '../../resources/media-queries.scss';

.contact{
    &__container{
        padding:20px;
        background-color: #edf7ee;

    }
    &__info-list{
        list-style-type: none;
        padding-left: 20px;
    }
    &__detail{
        font-size: 18px;
        line-height: 1.56;
        color: #20252c;
    }
    &__details-box{
        display: flex;
        flex-direction: row;
        padding:10px 0;
    }
    &__icon{
        position: relative;
        right: 10px;
        top: 5px;
    }
    &__form{
        display: flex;
        flex-direction: column;
        width:90%;
        justify-content: center;
    }
 
    &__input, textarea{
        border: none;
        border-bottom: 1px solid #888;
        background: transparent;
        margin:10px;
        padding:8px 0 0;
        width:100%;
        font-size: 18px;
        line-height: 1.56;
        font-weight: bold;
        color: #535f6e;
        text-indent: 5px;
    }
    
    &__input::placeholder{
        font-size: 16px;
        line-height: 1.56;
        color: #535f6e;
        font-family: 'Domine', serif;
    }
    textarea::placeholder{
        font-family: 'Domine', serif;
    }
    &__submit-button{
        width: 140px;
        height: 40px;
        border-radius: 5px;
        border:none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:10px auto;
        background-color: #4CAF50;
        cursor:pointer;
    }
    &__submit-button:hover{
        background-color: #edf7ee;
    }
    &__button-writing{
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
    }

    @include desktop{
        &__container{
            padding:40px 20%;
            display: flex;
            flex-direction: row;
        }
        &__form-box{
            width:40%;
            margin-left: 70px;
        }
        &__contact-box{
            margin-right: 20px;
        }
        &__form{
            width:100%;
        }
        &__submit-button{
            margin:10px;
        }
    }
}