@import './media-queries.scss';
.standard{

    h1, h2, h3,h4, h5, h6{
        margin:0;
    }
    h1, h2, h3{
        font-family: 'Domine', serif;
    }
    
    h4,h5,h6, li{
        font-family: 'Cabin', sans-serif;
    }
    &__first-section{
        padding:30px 20px 10px;
        background-color: #edf7ee;
    }
    &__general-description{
        font-size: 16px;
        font-weight: lighter;
        line-height: 1.67;
        color: #edf7ee;
    }
    &__general-title{
        font-size: 22px;
        font-weight: bold;
        line-height: 1.58;
        color: black;
        padding-bottom: 10px;
    }
    &__link{
        text-decoration: none;
        cursor: pointer;
    }
    &__section-title{
        font-size: 22px;
        line-height: 1.58;
        color: #20252c;
        padding-bottom: 10px;
      }
    &__schedule-box{
        background-color: #ffff;
        margin:20px;
        padding:30px 0;
    }
    &__schedule-title{
        font-size: 22px;
        line-height: 2.05;
        text-align: center;
        color: #20252c;
    }
    &__schedule-hour{
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        color: #535f6e;
    }
    &__schedule-class{
        font-size: 18px;
        line-height: 1.56;
        text-align: center;
        color: #20252c;
    }
    &__schedule-info{
        margin:10px auto;
    }
    &__schedule-container{
        display: flex;
        flex-direction: column;
        margin:30px 0;
    }
    &__add-grade{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__grade-input{
        width:40%!important;
    }
    &__grade-container{
        position: relative;
        margin-top: 60px;
    }
    &__close-icon{
        width:14px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    &__grade-action-button{
        width:50%!important;
    }
    &__add-note-button{
        border-radius: 5px;
        background-color: #006cd4;
        width: 130px;
        height: 40px;
        border:none;
        cursor: pointer;
        margin-top: 10px;
    }
    &__add-note{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
        font-family: Cabin;
        text-align: center;
      }
      &__red-button{
          background-color: red;
      }
      &__general-line{
        background-color: #4CAF50;
        height:2px;
        border-radius: 3px;
        width:100px;
    }

    @include tablet {
        &__schedule-box{
            margin:20px 40px;
        }
        &__first-section{
            padding:20px 40px;
        }
    }
    
    @include desktop {
        &__general-line{
            height:4px;
            width:80px;
            margin:0 auto;
        }
        &__general-description{
            font-size: 18px;
        }
        &__first-section{
            padding:20px 10% 60px;
        }
        &__general-description, &__general-title{
            text-align: center;
        }
        &__general-title{
            font-size: 24px;
            padding-top: 20px;
        }
        &__section-title{
            padding:20px 0;
        }
        &__general-description{
            width:40%;
            margin:0 auto!important;
        }
        &__schedule-container{
            flex-direction: row;
            margin:40px 10%;
        }
        &__schedule-box{
           width:100%;
        }
        &__close-icon{
            width:16px;
            top: 14px;
            right: 14px;
        }
        &__grade-action-container{
            padding-top: 50px;
        }
        &__add-note-modal{
            width:40%;
            height:40px;
        }
        &__grade-change-box{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
    }
}
