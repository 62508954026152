@import '../../resources/media-queries.scss';

.announcement{
    &__container{
        padding:20px;
        background-color: #edf7ee;
    }
    &__image{
        width:100%;
        height:400px;
        object-fit: cover;
    }
    &__info{
        color:#535f6e;
        padding:10px 0;
        font-size: 18px;
    }
    &__date{
        font-size: 13px;
        font-weight: 500;
        color: #a6c0dd;
    }

    @include desktop{
        &__container{
            padding:40px 25%;
        }
        &__date{
            display: flex;
            justify-content: center;
            padding-top: 10px;
        }
        &__info{
            text-align: initial;
            width:100%;
        }
    }
}

