@import '../../resources/media-queries.scss';

.announcements{
    &__wrapper{
        padding:20px;
    }
    &__card{
        border:1px solid #b8bcc0;
        margin:20px 0;
        border-radius: 5px;
    }
    &__card-title{
        font-size: 18px;
        line-height: 1.56;
        color: #4CAF50;
      
    }
    &__card-intro{
        h5{
            font-size: 13px;
            font-weight: bold;
            color: #535f6e;
          
        }
    }
    &__image{
        height:250px;
        width:100%;
    }
    &__card-info{
        font-size: 16px;
        line-height: 1.63;
        color: #535f6e;
        margin-top: 5px;
    }
    &__see-doc{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #4CAF50;
        cursor: pointer;
    }
    &__card-information{
        padding:10px 20px;
    }
    @include tablet{
        &__wrapper{
            padding:20px 40px;
        }
    }
    @include desktop{
        &__wrapper{
            padding:20px 10%;
        }
        &__date{
        width:20%;
        }
        &__container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &__card{
            width:30%;
            margin:20px 0;
        }
    }
}