@import '../../resources/media-queries.scss';

.about{
    &__container{
        padding:20px;
        background-color: #edf7ee;

    }
    &__image{
        width:100%;
        height:270px;
        object-fit: cover;
    }
    &__section-subtitle{
        padding-top: 20px;
        font-size: 18px;
        line-height: 1.56;
        color: #4CAF50;
    }
    &__description{
        color: #535f6e;
        padding:10px 0;
        font-size:18px;
        font-weight: bold;
    }
@include tablet{

}
@include desktop{
    &__container{
        padding:20px 25%;
    }
    &__description{
        text-align:initial;
        width:100%;
    }
}
}