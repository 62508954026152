@import '../../resources/media-queries.scss';

.results{
    &__container{
        padding:20px;
        background-color: #edf7ee;
    }
    &__subtitle{
        color: #20252c;
        font-size: 18px;
        line-height: 1.56;
        padding-bottom: 10px;
        cursor: pointer;
    }
    &__description{
        color:#535f6e;
        padding-left: 15px;
    }
    &__link{
        color:#002376;
        font-weight: bolder;
        padding:10px 0;
    }
    &__date-box{
        display: flex;
        flex-direction: row;
    }
    &__date{
        color:#737373;
    }
    &__box{
        display: flex;
        flex-direction: row;
        img{
            width:12x;
            height:12px;
            padding-right: 5px;
            margin-top: 8px;
        }
    }

    @include desktop{
        &__container{
            padding:40px 25%;
        }
        &__description, &__link{
            text-align: initial;
            width:100%;
        }
    }
}