@import '../../../resources/StandardStyles.scss';

    .hamburger{
        &__icon{
            width:30px;
            margin-right: 20px;
            position: relative;
            top: 5px;
            cursor: pointer;
        }
        &__intro{
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            img{
                width: 30px;
                height: 38px;
                margin-right: 5px;
            }
            h5{
                font-size: 14px;
                font-weight: 500;
                color: #edf7ee;
            }
            h4{
                font-size: 18px;
                font-weight: bold;
                color: #ffffff;
            }
        }
        &__close-icon {
            display: none;
            position: absolute;
            width: 26px;
            right: 3%;
            top: 16px;
            right:15px;
            z-index: 20;
        
            &.active {
              display: block;
            }
          }
        &__links {
            list-style: none;
            width: 90%;
            float: right;
            margin-right: 0;
            z-index: 20;
            clear: both;
            overflow: hidden;
            top: 0;
            right: 0;
            padding: 20px 0 0 80px;
            height: 100%;
            margin-top: 0;
            background-color: #4CAF50;
            border-right: solid 1px lightgrey;
            position: fixed;
            transform: translateX(100%);
            transition: transform .3s;
            overflow-y: auto;
    
        
            &.active {
              transform: translateX(0);
            }
        }
        &__list-item{
            font-family: 'Domine', serif;
            font-size: 18px;
            line-height: 1.24;
            color: #ffff;
            padding:10px;
        }
        &__link--menu{
            font-family: 'Domine', serif;
            font-size: 18px;
            font-weight: bolder;
            margin: 10px 0;
            color: #ffff;
            padding-left: 8px;
        }
        &__link{
            text-decoration: none;
            color:initial;
        }
        &__platform-access{
            border:none;
            border-radius: 5px;
            background-color: #ffffff;
            height:46px;
            width:90%;
            margin:20px 0 50px;
        }
        &__platform{
            color:#4CAF50;
            font-size: 14px;
        }
        &__link-box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__arrow{
            height:18px;
            width:18px;
            position: relative;
            top:20px;
            right:20px;
        }
        &__submenu-list-item{
            font-family: Domine;
            font-size: 16px;
            margin-left: 10px;
            color: #ffffff;
        }
    
        @include desktop{
            &__icon{
                display: none;
            }
        }
    }